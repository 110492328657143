import React, { useState, useEffect, } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import styled from "styled-components";

const useStyles = makeStyles(styles);

const Img = styled.img`

  width: 276px;
  max-width: 100%;
`;

export default function Header(props) {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir();
  const languagedata = useSelector(state => state.languagedata);
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [langSelection, setLangSelection] = useState();
  const [multiLanguage, setMultiLanguage] = useState();


  useEffect(() => {
    if (languagedata.langlist) {
      for (const key of Object.keys(languagedata.langlist)) {
        if (languagedata.langlist[key].default) {
          setLangSelection(key)
        }
      }
      setMultiLanguage(languagedata.langlist);
    }
  }, [languagedata.langlist]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { color, rightLinks, leftLinks } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    // [classes.absolute]: absolute,
    // [classes.fixed]: fixed
  });
  let history =useHistory()
  const brandComponent = <div
    onClick={(e) => { e.preventDefault(); history.push('/')}}
    className={classes.title}
    style={{cursor:"pointer",padding:"0"}}
    >
    <Img
            loading="lazy"
            srcSet="https://cdn.prod.website-files.com/66bd963fc68ecdd974d5d3d9/679cfbe18449f70b533b355a_Logo%20copy%202.png"
          />
    
  </div>;
  return (
    <AppBar className={appBarClasses} style={{padding:0}}>
      <Toolbar className={classes.container} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr',padding:"0px" }}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={isRTL === 'rtl' ? null : classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css" style={{ flexDirection: 'row' }}>
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>

        <div style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
          <Hidden smDown implementation="css">
            {rightLinks}
          </Hidden>
        </div>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
    "landing"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
      "header",
      "landing"
    ]).isRequired
  })
};
