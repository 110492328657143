import React, { useState, useEffect, useContext, useRef } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from "common";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { Button, Link } from "@material-ui/core";
import { Box, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import AlertDialog from "components/AlertDialog";
import { colors } from "../components/Theme/WebTheme";

import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import EastIcon from '@mui/icons-material/East';
import GoogleMapsAutoComplete from "components/GoogleMapsAutoComplete";
import UsersCombo from "components/UsersCombo";

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: "#de7d1e",
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    lineBreak: "anywhere",
    border: "2px solid #000",
    padding: theme.spacing(2, 4, 3),
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    "@media (max-width: 800px)": { width: "90%" },
    maxHeight: "70vh",
    overflow: "auto",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    fetchUsers,
    fetchRoutes,
    fetchStores
  } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector((state) => state.usersdata.users);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const [columns, setColumns] = useState([]);
  const routes = useSelector((state) => state.routes);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const classes = useStyles();
  const rootRef = useRef(null);

  useEffect(() => {
    if (routes.routes && routes.routes.length > 0) {
      setAvailableRoutes(routes.routes.filter((e) => e.isActive));
    }
  }, [routes]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch, fetchUsers]);

  const stores = useSelector(state => state.stores);
  const [availableStores,setAvailableStores] = useState([])
  const [selectedStore,setSelectedStore] = useState(null)
  
  useEffect(()=>{
    if(auth?.info?.profile?.usertype === 'fleetadmin'){
        dispatch(fetchStores(auth.info.uid));
    }
    else dispatch(fetchStores(auth.info.profile?.carrierId));
    
},[dispatch,fetchStores,auth?.info?.uid]);



useEffect(()=>{
  if(stores?.stores&&stores?.stores.length>0){
    const filteredStores = stores.stores
                        .filter(e=>e.isActive)
                        .map(e=>{
                          return{...e,desc:`${e.name} (${e?.isMain?"A2B Store":"Agent Store"})`}
                        })
    setAvailableStores(filteredStores)
  }
  else{
    setAvailableStores([])
  }
},[stores])



  const giveIdThenIwillGiveYouData = (id) => {
    if (!staticusers) return null;
    const targetUser = staticusers.filter((e) => {
      return e.id === id;
    });
    if (targetUser.length > 0) return targetUser[0];
    return null;
  };

  const isSpecialAdmin = () => {
    return (
      auth.info?.profile?.specialAdmin ||
      giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)?.specialAdmin
    );
  };

  const [routeModal, setRouteModal] = useState(false);
  const [rowToUpdate, setRowToUpdate] = useState({});
  const [workingRoute, setWorkingRoute] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (auth?.info?.profile?.usertype === "fleetadmin") {
      dispatch(fetchRoutes(auth.info.uid));
    } else dispatch(fetchRoutes(auth.info.profile?.carrierId));
  }, [dispatch, fetchRoutes, auth]);

  useEffect(() => {
    if (staticusers) {
      if (auth.info.profile.usertype === "admin") {
        let users = staticusers.filter(
          (user) => user.usertype === "dispatcher" && !user.independent
        );
        let userDetail = [];
        for (const user of users) {
          if (auth.info?.profile?.usertype === "fleetadmiin") {
            let carrier = staticusers.filter(
              (individual) => individual.id === user.carrierId
            );
            if (carrier.length > 0) {
              user["ownerName"] =
                carrier[0].firstName + " " + carrier[0].lastName;
              user["ownerEmail"] = carrier[0].email;
            }
            userDetail.push(user);
          } else if (auth.info?.profile?.usertype === "shipper") {
            let broker = staticusers.filter(
              (individual) => individual.id === user.brokerId
            );
            if (broker.length > 0) {
              user["ownerName"] =
                broker[0].firstName + " " + broker[0].lastName;
              user["ownerEmail"] = broker[0].email;
            }
            userDetail.push(user);
          } else if (
            auth.info?.profile?.usertype === "dispatcher" &&
            user.independent
          ) {
            let dispatcher = staticusers.filter(
              (individual) => individual.id === user.dispatcherId
            );
            if (dispatcher.length > 0) {
              user["ownerName"] =
                dispatcher[0].firstName + " " + dispatcher[0].lastName;
              user["ownerEmail"] = dispatcher[0].email;
            }
            userDetail.push(user);
          }
        }
        setData(userDetail);
      } else {
        const filterdUsers=  staticusers.filter(
          (user) =>
            user.usertype === "dispatcher" &&
            (user?.brokerId === auth.info.uid ||
              user?.carrierId === auth.info.uid ||
              user?.dispatcherId === auth.info.uid)
        )
        setData(filterdUsers.length===0?[]:filterdUsers.map(e=>{
          return {...e,tableData:null}
       }));
      }
    } else {
      setData([]);
    }
    loaded.current = true;
  }, [staticusers]);

  const languageChanger = (language) => {
    if (!language || language === "en") return "English";
    if (language === "amh") return "አማርኛ";
    return "Oromiffa";
  };

  const [storeCityModal, setStoreCityModal] = useState(false);
  const [storeCity, setStoreCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });


  const openStoreCityModal=(rowData)=>{
    setUserData(rowData);
    if(!storeCity||rowToUpdate?.storeCity!==rowData.id){
      setStoreCity(rowData.storeCity
        ? {
            coords: { lat: rowData.storeCity.lat, lng: rowData.storeCity.lng },
            description: rowData.storeCity.add,
            name: rowData.storeCity?.name,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, storeCity: rowData.id })); 

    // Open the modal
    setStoreCityModal(true);
  }

  const closeStoreCityModal=()=>{
    setStoreCityModal(false)
    setUserData(null)
  }



const handleSetStoreCity=()=>{
  if(!storeCity) return
  if(userData&&storeCity){
      setLoading(true)
   
      const newStoreity = {
        add: storeCity?.description,
        lat: storeCity?.coords.lat,
        lng: storeCity?.coords.lng,
        name:storeCity?.name,
        id:storeCity?.id,
      };
      
      dispatch(editUser(userData.id, {
            ...userData,
          storeCity: newStoreity,
          workingRoute:[]
         },userData)
        );
      dispatch(api.fetchUsersOnce());
  }else {
    setCommonAlert({ open: true, msg: "Select Agents Store" });
  }
  setStoreCityModal(false)
  setLoading(false);
}


useEffect(() => {
    setColumns([
      {
        title: t("first_name"),
        field: "firstName",
        initialEditValue: "",
        render: (rowData) => (
          <div style={{ color: !rowData.approved && "red" }}>
            {rowData.approved ? (
              <Link
                target="_blank"
                href={`/user/${rowData.id}?redirected=internal-reps`}
              >
                {rowData.firstName}
              </Link>
            ) : (
              rowData.firstName
            )}
          </div>
        ),
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 50 : 15,
          width: 150,
          minWidth: 150,
        },
      },
      {
        title: t("last_name"),
        field: "lastName",
        initialEditValue: "",
        render: (rowData) => (
          <div style={{ color: !rowData.approved && "red" }}>
            {rowData.approved ? (
              <Link
                target="_blank"
                href={`/user/${rowData.id}?redirected=internal-reps`}
              >
                {rowData.lastName}
              </Link>
            ) : (
              rowData.lastName
            )}
          </div>
        ),
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 150,
          minWidth: 150,
        },
      },
      { 
        title:"Store", 
        field: 'storeCity',
        editable: "onUpdate",
        render: (rowData) =>
        rowData.storeCity||storeCity?.name ? (
            <div>
              <p>{(storeCity?.name&&rowToUpdate?.storeCity===rowData.id)?storeCity?.name:rowData?.storeCity?.name||"Add"}</p>
            </div>
          ) : (
            "Missing Store"
          ),
        editComponent: (props) => (
          <button onClick={() => openStoreCityModal(props.rowData)}>
            {props.rowData.storeCity||storeCity?.name ? (
              <div>
                 <p>{(storeCity?.name&&rowToUpdate?.storeCity===props.rowData.id)?storeCity?.name:props.rowData?.storeCity?.name||"Add"}</p>
              </div>
            ) : (
              "Add"
            )}
          </button>
        ),
        headerStyle: { textAlign: "center",minWidth:200,width:200 },
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          minWidth:200,width:200,
          textAlign: "center"
        },
     
     },
      {
        title: "Language",
        field: "language",
        editable: "never",
        render: (rowData) => (
          <div>
            {rowData.language ? (
              <>
                <div>{languageChanger(rowData.language)}</div>
                <div style={{ fontSize: 11, opacity: 0.7 }}>
                  {moment(rowData.languageUpdatedAt).fromNow()}
                </div>
              </>
            ) : (
              "English"
            )}
          </div>
        ),
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 150,
          minWidth: 150,
        },
      },
      {
        title: t("email"),
        field: "email",
        editable: "onAdd",
        render: (rowData) =>
          settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
        headerStyle: { textAlign: "center" },
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 180,
          minWidth: 180,
        },
      },
      {
        title: "Telegram Username",
        field: "TelegramId",
        editable: "never",
        render: (rowData) => {
          return (
            <div>
              {rowData?.TelegramId?.username ? (
                <a
                  target="_blank"
                  href={`https://web.telegram.org/k/#@${rowData?.TelegramId?.username}`}
                >
                  {rowData?.TelegramId.username}
                </a>
              ) : (
                "Not Added"
              )}
            </div>
          );
        },
        cellStyle: {
          width: 200,
          minWidth: 200,
          textAlign: "center",
        },
        headerStyle: {
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
        },
      },
      {
        title: t("mobile"),
        field: "mobile",
        editable: "onAdd",
        render: (rowData) =>
          settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 180,
          minWidth: 180,
        },
      },
      {
        title: "Assigned Routes",
        field: "workingRoute",
        editable: "onUpdate",
        render: (rowData) => (
          <>
            {routes?.loading ? (
              <CircularProgress />
            ) : (
              <>
                {onlyActiveRoutes(rowData.workingRoute) &&
                onlyActiveRoutes(rowData.workingRoute).length > 0 ? (
                  <div>
                    {onlyActiveRoutes(rowData.workingRoute).map((e, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            marginBottom: 4,
                            fontWeight: 700,
                            borderBottom: "1px solid black",
                          }}
                        >
                          {targetRoute(e)?.pickupAddress} to{" "}
                          {targetRoute(e)?.dropAddress}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>Not Assigned</div>
                )}
              </>
            )}
          </>
        ),
        editComponent: (props) => (
          <span>
            {routes?.loading ? (
              <CircularProgress />
            ) : (
              props?.rowData?.storeCity?.add?
              <Button
                style={{ background: pageBackgroundColor(), color: "white" }}
                onClick={() => openRouteModal(props.rowData)}
              >
                {onlyActiveRoutes(workingRoute) &&
                onlyActiveRoutes(workingRoute).length > 0 &&
                rowToUpdate?.workingRoute === props.rowData.id ? (
                  <div>
                    {onlyActiveRoutes(workingRoute).map((e, index) => {
                      return (
                        <div
                          style={{
                            marginBottom: 4,
                            fontWeight: 700,
                            borderBottom: "1px solid black",
                          }}
                          key={index}
                        >
                          {targetRoute(e)?.identification}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    {onlyActiveRoutes(props?.rowData.workingRoute) &&
                    onlyActiveRoutes(props?.rowData.workingRoute).length > 0 ? (
                      <div>
                        {onlyActiveRoutes(props.rowData.workingRoute).map(
                          (e, index) => {
                            return (
                              <div
                                style={{
                                  marginBottom: 4,
                                  fontWeight: 700,
                                  borderBottom: "1px solid black",
                                }}
                                key={index}
                              >
                                {targetRoute(e)?.identification}
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      "Add Route"
                    )}
                  </>
                )}
              </Button>
              :
              <Typography>No Route around your store</Typography>
            )}
          </span>
        ),
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 180,
          minWidth: 180,
        },
      },
      {
        title: "Open for Work",
        field: "activeStatus",
        type: "boolean",
        editable: "never",
        cellStyle: { textAlign: "center" },
      },
      {
        title: "Account Status",
        field: "approved",
        type: "boolean",
        initialEditValue: true,
        cellStyle: { textAlign: "center" },
      },
      {
        title: "Telegram Verified",
        field: "emailVerified",
        type: "boolean",
        editable: "never",
        cellStyle: { textAlign: "center" },
      },
      {
        title: t("createdAt"),
        field: "createdAt",
        editable: "never",
        defaultSort: "desc",
        render: (rowData) =>
          rowData.createdAt ? moment(rowData.createdAt).format("lll") : null,
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 180,
          minWidth: 180,
        },
      },
    ]);
  }, [auth.info.profile.usertype, availableRoutes, data, routes]);

  const targetRoute = (workingRoute) => {
    if (!workingRoute || availableRoutes.length === 0) return null;
    const route = availableRoutes.filter((e) => {
      return e.identification === workingRoute;
    });

    return route.length > 0 ? route[0] : null;
  };

  const [listOfTargetsForAgent,setListOfTargetsForAgent] = useState([])


  const openRouteModal = (rowData) => {
    if(!rowData?.storeCity) return
    setUserData(rowData);


    const filterAvailableRoutes  = availableRoutes.filter(e=>{
       return e.drop?.description  ===  rowData?.storeCity?.add  || e.pickup?.description  ===  rowData?.storeCity?.add
    })
    setListOfTargetsForAgent(filterAvailableRoutes)
    if (!workingRoute || rowToUpdate?.workingRoute !== rowData.id) {
      setWorkingRoute(rowData.workingRoute || []);
    }
    if (rowData) setRowToUpdate((e) => ({ ...e, workingRoute: rowData.id }));
    setRouteModal(true);
  };

  const pageBackgroundColor = () => {
    if (auth?.info?.profile?.dashboardColor)
      return auth?.info?.profile?.dashboardColor;
    if (
      giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
    )
      return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)
        ?.dashboardColor;
    return "#01aac1";
  };

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleRouteModal = (e) => {
    setRouteModal(false);
    setUserData(null);
  };

  const handleSetRouteModal = (e) => {
    e.preventDefault();
    if (workingRoute) {
      setLoading(true);

      if (userData) {
        dispatch(
          editUser(
            userData.id,
            { ...userData, workingRoute: workingRoute },
            userData
          )
        );
        // setTimeout(() => {
          dispatch(fetchUsers());
        // }, 1000);
      }
    } else {
      setCommonAlert({ open: true, msg: "Select Return Home Date" });
    }

    setLoading(false);
    handleRouteModal();
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  function getStyles(name, workingRoute, theme) {
    return {
      fontWeight:
        workingRoute.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setWorkingRoute(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onlyActiveRoutes = (allRoutes) => {
    if (!allRoutes || allRoutes.length == 0) return [];
    const filteredRoutes = allRoutes.filter((e) => {
      return targetRoute(e)?.isActive;
    });
    

    return filteredRoutes;
  };


  return !loaded.current ? (
    <CircularLoading />
  ) : (
    <div>
      <MaterialTable
        title={"manage Agents"}
        columns={columns}
        style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        data={data}
        options={{
          exportButton: settings.AllowCriticalEditsAdmin,
          sorting: true,
        }}
        localization={{
          body: {
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit"),
            emptyDataSourceMessage: t("blank_message"),
            editRow: {
              deleteText: t("delete_message"),
              cancelTooltip: t("cancel"),
              saveTooltip: t("save"),
            },
          },
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            labelRowsSelect: t("rows"),
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              if (
                !newData.email ||
                !isValidEmail(newData.email) ||
                !newData.mobile
              ) {
                alert(t("email_or_mobile_issue"));
                reject();
              } else {
                checkUserExists(newData).then((res) => {
                  if (res.users && res.users.length > 0) {
                    alert(t("user_exists"));
                    reject();
                  } else if (res.error) {
                    alert(t("email_or_mobile_issue"));
                    reject();
                  } else if (auth.info.profile.usertype !== "admin") {
                    newData["regType"] = "admin";
                    newData["usertype"] = "dispatcher";
                    newData["createdAt"] = new Date().toISOString();
                    if (auth.info?.profile?.usertype === "fleetadmin")
                      newData["carrierId"] = auth.info.uid;
                    if (auth.info?.profile?.usertype === "shipper")
                      newData["brokerId"] = auth.info.uid;
                    if (
                      auth.info?.profile?.usertype === "dispatcher" &&
                      auth.info?.profile?.independent
                    )
                      newData["dispatcherId"] = auth.info.uid;

                    dispatch(addUser(newData));
                    setTimeout(() => {
                      dispatch(fetchUsers());
                      resolve();
                    }, 400);
                  } else {
                    resolve();
                  }
                });
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              if (auth.info.profile.usertype !== "admin") {
                if (workingRoute && rowToUpdate?.workingRoute === newData.id) {
                  newData["workingRoute"] = workingRoute;
                }
                if (storeCity && rowToUpdate?.storeCity === newData.id) {
                  const newStoreity = {
                    add: storeCity?.description,
                    lat: storeCity?.coords.lat,
                    lng: storeCity?.coords.lng,
                    name:storeCity?.name
                  };
                  newData["storeCity"] = newStoreity;
                  
                }
                dispatch(editUser(oldData.id, newData));
                setRowToUpdate({});
                setTimeout(() => {
                  dispatch(fetchUsers());
                  resolve();
                })
              }
            }) ,

          onRowDelete: (oldData) =>
            settings.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  if (auth.info.profile.usertype !== "admin") {
                    dispatch(deleteUser(oldData.id));
                    setTimeout(() => {
                      dispatch(fetchUsers());
                      resolve();
                    }, 400);
                  } else resolve();
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(t("demo_mode"));
                  }, 600);
                }),
        }}
      />

      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={routeModal}
        onClose={handleRouteModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Box spacing={1} className={classes.paper}>
          {listOfTargetsForAgent && listOfTargetsForAgent.length > 0 ? (
            <>
              <Box item xs={12} style={{ width: "100%" }}>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel id="demo-multiple-chip-label">
                    Available Routes
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={onlyActiveRoutes(workingRoute)}
                    onChange={handleChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {listOfTargetsForAgent.map((route) => (
                      <MenuItem
                        key={route.identification}
                        value={route.identification}
                        style={getStyles(
                          route.identification,
                          onlyActiveRoutes(workingRoute),
                          theme
                        )}
                      >
                            <Box sx={{display:"flex",alignItems:"center",gap:0.3,flexWrap:"wrap"}}>
                            <Box sx={{fontSize:14}}> {route?.pickupAddress||route?.pickup?.description}</Box>
                             <Box><EastIcon style={{fontSize:15,marginTop:8}}/></Box>
                             <Box sx={{fontSize:14}}>{route?.dropAddress||route?.drop?.description}</Box>
                            <Box style={{fontWeight:"700"}}>({route?.identification})</Box>
                         </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                {loading ? (
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "5vh" }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
                  >
                    <Button
                      onClick={handleRouteModal}
                      variant="contained"
                      color="dangerColor"
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      onClick={handleSetRouteModal}
                      variant="contained"
                      color="secondaryButton"
                      style={{
                        marginLeft: 10,
                        color: "white",
                        background: pageBackgroundColor(),
                      }}
                    >
                      {t("save")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <Typography style={{ fontWeight: 700, fontSize: 20 }}>
              No Available routes found
            </Typography>
          )}
        </Box>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={storeCityModal}
        onClose={closeStoreCityModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Box spacing={1} className={classes.paper}>
          <Grid item xs={12} style={{ width:"100%"}}>
            {/* <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"Store City"}
              value={storeCity}
              className={classes.items}
              onChange={(value) => {
                setStoreCity(value);
              }}
            /> */}
             <UsersCombo
                className={classes.items}
                placeholder={"Choose Store"}
                users={availableStores}
                value={selectedStore}
                onChange={(event, newValue) => {
                  setStoreCity({...newValue?.Location,name:newValue?.name,id:newValue?.id})
                  setSelectedStore(newValue);
                }}
              />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left",marginTop:15 }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetStoreCity}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10,background:pageBackgroundColor(),color:"#ffff" }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Box>
      </Modal>

    </div>
  );
}
