import React, { useState, useEffect, useContext, useRef } from 'react';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import AlertDialog from '../components/AlertDialog';
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import { colors } from "../components/Theme/WebTheme";
import EditIcon from '@mui/icons-material/Edit';
import CardContent from '@material-ui/core/CardContent';
import {
  Modal,
  Box,
  Switch
} from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { FormControl, FormControlLabel, InputLabel,  MenuItem, Select,
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#01aac1"
  },
  rootRtl: {
    "& label": {
      right: 10,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20
    }
  },
  rootRtl_1: {
    "& label": {
      right: 10,
      left: "auto",
      paddingRight: 33
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25
    }
  },
  submit2: {
    marginLeft:75,
    top:-40,
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#019b9d'
  },
}));

const MyProfile = () => {
  const { api } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    updateProfile,
    updateWebProfileImage,
    fetchUsersOnce
  } = api;
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const staticusers = useSelector((state) => state.usersdata.users);
  const classes = useStyles();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const languagedata = useSelector(state => state.languagedata);
  const [langSelection, setLangSelection] = useState(0);
  const [multiLanguage,setMultiLanguage] = useState();
  const fileInputRef=useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const settings = useSelector(state => state.settingsdata.settings);
  const [driverTrackingType, setDriverTrackingDevice] =useState(null)
  const [trackingSelection, setTrackingSelection] = useState('select');
  const [SelectTrackingonOnDriverPage, SetSelectTrackingonOnDriverPage] = useState(false)

  const handleChange = (event) => {
    SetSelectTrackingonOnDriverPage(event.target.checked);
  };

  useEffect(()=>{

    if(auth.info?.profile?.trackigType){
      setTrackingSelection(auth.info?.profile?.trackigType)
    }
    if(auth.info?.profile){
      SetSelectTrackingonOnDriverPage(auth.info?.profile?.SelectTrackingonOnDriverPage)
    }

  },[auth])

  function isChannelInformationExists(channelLink,channelId){
       if(!staticusers||staticusers.length<1) return false

       let userExists=staticusers.filter(e=>{
        return e.id!==auth.info.uid&&(e.channelLink===channelLink||e.channelId===channelId)
       }).length>0
       
       if(userExists) return true
  }
  
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    regType: null,
    usertype: '',
    website: '',
    companyName: '',
    channelId: '',
    channelLink:"",
    adminBankAccount:"",
    dashboardColor:""
  });
  const rootRef = useRef(null);

  useEffect(()=>{
    if(languagedata.langlist){
      let arr = Object.keys(languagedata.langlist);
      for (let i=0; i< arr.length; i++) {
        if(auth.info.profile && auth.info.profile.lang && auth.info.profile.lang.langLocale && auth.info.profile.lang.langLocale === languagedata.langlist[arr[i]].langLocale){
          setLangSelection(i);
        }
      }
      setMultiLanguage(languagedata.langlist);
    }
  },[languagedata.langlist,auth.info,setMultiLanguage,setLangSelection]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setData({
        firstName: !auth.info.profile.firstName || auth.info.profile.firstName === ' ' ? '' : auth.info.profile.firstName,
        lastName: !auth.info.profile.lastName || auth.info.profile.lastName === ' ' ? '' : auth.info.profile.lastName,
        email: !auth.info.profile.email || auth.info.profile.email === ' ' ? '' : auth.info.profile.email,
        mobile: !auth.info.profile.mobile || auth.info.profile.mobile === ' ' ? '' : auth.info.profile.mobile,
        regType:auth.info.profile.regType,
        usertype: auth.info.profile.usertype,
        uid: auth.info.uid,
        profile_image: auth.info.profile.profile_image ?  auth.info.profile.profile_image : '',
        ...(auth.info.profile.usertype === 'shipper' || auth.info.profile.usertype === 'fleetadmin') && {
        website: !auth.info.profile.website || auth.info.profile.website === '' ? '' : auth.info.profile.website,
        companyName: !auth.info.profile.companyName || auth.info.profile.companyName === '' ? '' : auth.info.profile.companyName,
        channelId: !auth.info.profile.channelId || auth.info.profile.channelId === '' ? '' : auth.info.profile.channelId,
        channelLink: !auth.info.profile.channelLink || auth.info.profile.channelLink === '' ? '' : auth.info.profile.channelLink,
        adminBankAccount: !auth.info.profile.adminBankAccount || auth.info.profile.adminBankAccount === '' ? '' : auth.info.profile.adminBankAccount,
        dashboardColor: !auth.info.profile.dashboardColor || auth.info.profile.dashboardColor === '' ? '' : auth.info.profile.dashboardColor,
       }
      });
    }
  }, [auth.info]);

  const updateData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function isSuperDispatcher(){
    return auth.info.profile.usertype==='dispatcher'&&auth.info.profile.independent
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (re.test(data.email)) {
      setLoading(true);
      let arr = Object.keys(languagedata.langlist);
      let dataToBeUpdated={
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        mobile: data.mobile,
        lang: {langLocale: multiLanguage[arr[langSelection]].langLocale, dateLocale:multiLanguage[arr[langSelection]].dateLocale },
       
      }
      if(auth.info.profile.usertype==='fleetadmin'||auth.info.profile.usertype==='shipper'||isSuperDispatcher()){
        dataToBeUpdated['website']=data.website||""
        dataToBeUpdated['companyName']=data.companyName||""
        dataToBeUpdated['channelId']=data.channelId||""
        dataToBeUpdated['channelLink']=data.channelLink||""
        dataToBeUpdated['adminBankAccount']=data.adminBankAccount||""
        dataToBeUpdated['dashboardColor']=data.dashboardColor||""        
      }
      if(auth.info.profile.usertype==='fleetadmin'){
          if(driverTrackingType !== 'select')  dataToBeUpdated['trackigType']=trackingSelection 
          if(SelectTrackingonOnDriverPage ===  true || SelectTrackingonOnDriverPage===false  ) dataToBeUpdated['SelectTrackingonOnDriverPage']=SelectTrackingonOnDriverPage 
        
      }
      
      if(dataToBeUpdated.adminBankAccount && !dataToBeUpdated?.adminBankAccount?.startsWith("1000")){
        setCommonAlert({ open: true, msg: `Please use Valid CBE Account. Your CBE account  Should start with 1000`});
        setLoading(false);
      }
      else if((dataToBeUpdated['channelId']||dataToBeUpdated['channelLink'])&&isChannelInformationExists(dataToBeUpdated['channelLink'],dataToBeUpdated['channelId'])){
        setCommonAlert({ open: true, msg: `This channel information is already registered, please try with new`});
        setLoading(false);
      }
      else {
            dispatch(updateProfile(auth.info, dataToBeUpdated));             
            const lang = multiLanguage[arr[langSelection]];
            i18n.addResourceBundle(multiLanguage[arr[langSelection]].langLocale, 'translations', multiLanguage[arr[langSelection]].keyValuePairs);
            i18n.changeLanguage(lang.langLocale);
            moment.locale(lang.dateLocale);
            setTimeout(()=>{     
              setLoading(false);
              setCommonAlert({ open: true, msg: t('profile_updated') });
            },1000)
            
        
        } 
      
    } 
    // else if(!urlPattern.test(data.website)) {
    //   setCommonAlert({ open: true, msg: 'please enter your website properly' })
    // }
    else {
      setCommonAlert({ open: true, msg: t('proper_email') })
    }
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const handleLanguageSelect = (event) => {
    setLangSelection(event.target.value);
  };

  const handleDriverSelectSelect=(event)=>{
    setTrackingSelection(event.target.value);
  }


  const profileImageChange = async (e)=>{
    if(auth?.info?.profile?.usertype ===  'shipper' || auth?.info?.profile?.usertype ===  'fleetadmin' || (auth?.info?.profile?.independent)){
      const targetImage =  e.target?.files[0]
      if(targetImage){
          setSelectedImage(targetImage);
          dispatch(updateWebProfileImage(auth.info, targetImage));    
      }
    }
  }

  const [showTextForNonCarrier,setshowTextForNonCarrier]=useState(!auth.info.profile.TelegramId?.username&&auth.info.profile.usertype!=='fleetadmin')
  const [ownerName, setOwnerName]=useState(null)
  useEffect(()=>{
        if(auth.info?.profile.usertype==='dispatcher' && staticusers&& staticusers?.length>0){
             let owner = staticusers.filter(e=>{
                return e.id === auth.info?.profile?.carrierId || e.id === auth.info?.profile?.brokerId || e.id === auth.info?.profile?.dispatcherId
             })

             if(owner.length>0){
              setOwnerName({usertype:owner[0].usertype==='shipper'?"broker":owner[0].usertype,name:`${owner[0].firstName} ${owner[0].lastName}`})
             }
        }
  },[staticusers,auth])  

  
  useEffect(()=>{
        const country = auth.info?.profile?.selectedcountry?.code
        if(auth.info?.profile?.trackigType &&  auth.info?.profile?.trackigType !== 'select'){
            if(country === 'US'){
              setDriverTrackingDevice([
                      {
                        label:'Mobile App GPS',
                        value:'mobile',
                      },
                      {
                        label:'ELD Device',
                        value:'eld',
                      },{
                        label:"asset",
                        value:"Asset Tracker"
                      }
                  ])
            }
            else setDriverTrackingDevice([
                {
                label:'Mobile App GPS',
                value:'mobile',
                },
                {
                  label:'Gps Asset Tracker',
                  value:'fleetDevice',
                }
              ])

        }
        else {

          if(country === 'US'){
            setDriverTrackingDevice([
                {
                  label:'Not Set',
                  value:'select',
                },
                {
                  label:'Mobile App GPS',
                  value:'mobile',
                },
                {
                  label:'ELD Device',
                  value:'eld',
                },
                {
                  label:"asset",
                  value:"Asset Tracker"
                }
              ])
          }
          else setDriverTrackingDevice ([
              {
              label:'Not Set',
              value:'select',
            },
              {
              label:'Mobile App GPS',
              value:'mobile',
              },
              {
                label:'Gps Asset Tracker',
                value:'fleetDevice',
              }
          ])
        }

  },[auth])


  const pageBackgroundColor=()=>{
    if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
    if(giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
    return "#01aac1"
}
const giveIdThenIwillGiveYouData=(id)=>{
  if(!staticusers) return null
  const targetUser =staticusers.filter(e=>{
   return e.id===id
})
  if(targetUser.length>0) return targetUser[0]
  return null
}

  return (
    auth.loading ? <CircularLoading /> :
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
            {/* <Typography component="h1" variant="h4" style={{ marginTop: '8px', textAlign: 'left' }}>
              {t('profile')}
            </Typography> */}
            <CardContent className={classes.img} style={{width:'100%'}}>
              {selectedImage ?
                   <div  onClick={()=>fileInputRef.current.click()} style={{display:'flex',justifyContent:'center'}}>
                      <img src = {URL.createObjectURL(selectedImage)} alt='Profile'
                      style={{width:180, height:160, borderRadius: '50%'}}/>
                     </div>
                :
                data.profile_image ?
                    <div  onClick={()=>fileInputRef.current.click()} style={{display:'flex',justifyContent:'center'}}>
                      <img src = {data.profile_image} alt='Profile'
                        style={{width:150, height:150, borderRadius: '50%'}}/>
                          </div>
                    :
                    <div  onClick={()=>fileInputRef.current.click()} style={{display:'flex',justifyContent:'center'}}>
                      <img src = {require("../assets/img/profilePic.png").default} alt='Profile'
                      style={{width:150, height:150, borderRadius: '50%'}}/>
                    </div>
                } 
                { (auth?.info?.profile?.usertype ===  'shipper' || auth?.info?.profile?.usertype ===  'fleetadmin' || auth?.info?.profile?.independent)  &&
                   <input onChange={(event)=>profileImageChange(event)} multiple={false} ref={fileInputRef} type='file' hidden/>}
            </CardContent> 


            {(auth.info.profile?.TelegramId?.username &&( auth.info.profile?.usertype !== 'fleetadmin' && auth.info.profile?.usertype !== 'shipper' &&  !isSuperDispatcher()))&&
             <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"10px"}}>
                <div>Telegram username: <a target='__blank' href={`https://t.me/${auth.info.profile.TelegramId.username}`}>@{auth.info.profile.TelegramId.username}</a></div>
                <div><a target='__blank' style={{color:"black"}} href={settings.officialBotLink}><EditIcon style={{fontSize:"17px"}}/></a></div>
            </div>}

           {ownerName&&<Box>You have been added as a Internal Agent from {ownerName?.usertype==='dispatcher'?'Dispatch Agencies':ownerName?.usertype} {ownerName?.name}</Box>}

            <TextField
              className={isRTL === "rtl" ? classes.rootRtl : null}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label={t('firstname')}
              name="firstName"
              autoComplete="firstName"
              onChange={updateData}
              value={data.firstName}
              autoFocus
            />
            <TextField
              className={isRTL === "rtl" ? classes.rootRtl_1 : null}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label={t('lastname')}
              name="lastName"
              autoComplete="lastName"
              onChange={updateData}
              value={data.lastName}
            />
            <TextField
              className={isRTL === "rtl" ? classes.rootRtl : null}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('email')}
              name="email"
              autoComplete="email"
              onChange={updateData}
              value={data.email}
              disabled={(data.regType==='email'  || data.regType==='admin' || data.regType==='registration') ? true : false}
            />
            <TextField
              className={isRTL === "rtl" ? classes.rootRtl : null}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="mobile"
              label={t('phone')}
              name="mobile"
              autoComplete="mobile"
              onChange={updateData}
              value={data.mobile}
              disabled={(data.regType==='mobile'  || data.regType==='admin' || data.regType==='registration') ?true:false}
            />
            <TextField
              className={isRTL === "rtl" ? classes.rootRtl : null}
              variant="outlined"
              margin="normal"
              fullWidth
              id="usertype"
              label={t('usertype')}
              name="usertype"
              value={data.usertype}
              disabled={true}
            />
            

            {(data.usertype === 'fleetadmin'||data.usertype === 'shipper'||isSuperDispatcher()) && 
              <>
                <TextField
                className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                variant="outlined"
                margin="normal"
                fullWidth
                id="website"
                label="Website"
                name="website"
                autoComplete="website"
                onChange={updateData}
                value={data.website}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                       https://
                    </InputAdornment>
                  ),
                }}
               />
              <TextField
                className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                variant="outlined"
                margin="normal"
                fullWidth
                id="companyName"
                label="Company Name"
                name="companyName"
                autoComplete="companyName"
                onChange={updateData}
                value={data.companyName}
              />

              <TextField
               className={isRTL === "rtl" ? classes.rootRtl_1 : null}
               variant="outlined"
               margin="normal"
               fullWidth
               id="channelLink"
               label="Telegram Channel Link"
               name="channelLink"
               autoComplete="channelLink"
               onChange={updateData}
               value={data.channelLink}
               InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                     https://t.me/
                  </InputAdornment>
                ),
              }}
              />

            <TextField
               className={isRTL === "rtl" ? classes.rootRtl_1 : null}
               variant="outlined"
               margin="normal"
               fullWidth
               id="adminBankAccount"
               type='number'
               label="CBE Bank Account"
               name="adminBankAccount"
               autoComplete="adminBankAccount"
               onChange={updateData}
               value={data.adminBankAccount}
               placeholder='1000XXXXXXXX'
              />

            <TextField
               className={isRTL === "rtl" ? classes.rootRtl_1 : null}
               variant="outlined"
               margin="normal"
               type="color"
               fullWidth
               id="dashboardColor"
               label="Dashboard Color"
               name="dashboardColor"
               autoComplete="dashboardColor"
               onChange={updateData}
               value={data.dashboardColor}
               placeholder='Color'
              />

               <TextField
               className={isRTL === "rtl" ? classes.rootRtl_1 : null}
               variant="outlined"
               margin="normal"
               fullWidth
               id="channelId"
               label="Telegram Channel Id"
               name="channelId"
               autoComplete="channelId"
               onChange={updateData}
               value={data.channelId}
               InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                     -100
                  </InputAdornment>
                ),
              }}
              />

            </>
            }

            {multiLanguage ?
              <Select
                id="language-select"
                className={classes.input}
                value={langSelection}
                variant="outlined"
                onChange={handleLanguageSelect}
                style={{ backgroundColor: '#fff', width: '100%', marginTop: '16px', }}
              >
                {
                  Object.keys(multiLanguage).map((key,index) => <MenuItem key={key} value={index}>
                    {multiLanguage[key].langName}
                  </MenuItem>)
                }
              </Select>
              : null}

            
          {driverTrackingType&&driverTrackingType.length>0 && data.usertype === 'fleetadmin' ?
              <>
                <FormControl fullWidth sx={{margin:"20px 0 15px"}}>
                    <InputLabel id="demo-simple-select-label">Default GPS Tracking Method</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleDriverSelectSelect}
                      label="Default Tracking GPS"
                      value={trackingSelection}
                      variant="outlined"
                      style={{background:"transparent"}}
                    >
                      {
                        driverTrackingType.map((e,index) => {
                          return <MenuItem key={index} value={e.value}>
                                 {e.label}
                        </MenuItem>
                        })
                      }
                    </Select>
                </FormControl>


                  <FormControlLabel
                    control={
                      <Switch checked={SelectTrackingonOnDriverPage}  onChange={handleChange} name="gilad" />
                    }
                    label="Assign Different GPS Tracking Method For Each Driver"
                  />

              </>

              : null}


              {loading ?
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: '3vh', paddingTop: 20}} >
                  <CircularProgress/>
                </Grid>
              :
                <>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondaryButton"
                        className={classes.submit}
                        style={{backgroundColor:pageBackgroundColor()}}
                      >
                         Update
                      </Button>
                
                </>
              }
          </form>
        </div>

        {(auth.info.profile.usertype==='fleetadmin'||auth.info.profile.usertype==='shipper'||isSuperDispatcher())&&<div>
            <div style={{fontWeight:"600"}}>Why I need Telegram Channel ? </div>
            <p style={{fontSize:"13px"}}>
            By having a Telegram channel, you enable customers to visit and receive regular updates about 
            your business. It's an excellent opportunity to showcase your profile. Our bot will provide essential information, 
            such as when you accept new bookings, thereby enhancing your presence on the channel.
            </p>
            <div style={{fontWeight:"600"}}>How do the bot send messages on your channel ? </div>
            <p style={{fontSize:"13px"}}>
               fFirst, ensure that you have entered the correct Telegram ID of your channel and its channel link. 
               Next, make our <a href={settings.officialBotLink} target='__blank'>Bot</a> an admin and give all permission on your channel. 
               That's it! You will start receiving information on your channel.
            </p>
        </div>}

        {(auth.info.profile.usertype!=='fleetadmin'&&auth.info.profile.usertype !=='shipper'&&!isSuperDispatcher())&&<Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={showTextForNonCarrier}
        onClose={()=>setshowTextForNonCarrier(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            width:"50%",
            maxWidth:400,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
            <div style={{padding:"30px"}}>
                Would you be interested in registering your Telegram ID for real-time notifications? 
                Please note that for successful registration, your Telegram phone number should match your profile phone number.
                <div style={{marginTop:"17px",display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                     <div><a href={settings.officialBotLink} target='__blank'> Yes, i am</a></div>
                     <div><a style={{color:"blue",cursor:"pointer"}} onClick={()=>setshowTextForNonCarrier(false)}> No, thanks</a></div>
                </div>
            </div>
          
        </Grid>
      </Modal>}


        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
      </Container>
  );
};

export default MyProfile;