/*eslint-disable*/
import React from "react";
import styled from "styled-components";
import { Link } from "@material-ui/core";
import { Box } from "@mui/material";
import LinkedIn from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material';
import footerBgImage from "../../assets/img/footerBgImage.png"

export default function Footer(props) {


  return (
    <Div71 >
      <Div72>
        <Div74>
          <Column14>
            <Div79>
               <Link href="https://app.fleetcanvas.com">
                  <Img17
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&"
                  />
               </Link>
            </Div79>
          </Column14>

          <Column14>
            <Div79>
              <Div80>Contact</Div80>
              <Div81>
                  <Link href="mailto:info@fleetcanvas.com?subject=Info" style={{color:"inherit",textDecoration:"none",fontWeight: "400"}}>info@fleetcanvas.com</Link>
              </Div81>
              <Div81>
                  <Link href="mailto:support@fleetcanvas.com?subject=Info" style={{color:"inherit",textDecoration:"none",fontWeight: "400"}}>support@fleetcanvas.com</Link>
              </Div81>
                
                
            </Div79>
          </Column14>

          <Column14>
            <Div79>
              <Div80>Resources</Div80>
              <Div81>
                  <Link href="https://app.fleetcanvas.com/privacy-policy" style={{color:"inherit",textDecoration:"none",fontWeight: "400"}}>Privacy policy</Link>
              </Div81>                
                
            </Div79>
          </Column14>

          <Column14>
            <Div79>
              <Div80>About</Div80>
              <Div81>
                  <Link href="https://app.fleetcanvas.com/about-us" style={{color:"inherit",textDecoration:"none",fontWeight: "400"}}>About us</Link>
              </Div81>                
            </Div79>
          </Column14>
          
        </Div74>
        <Div94 />
        <Div95 >Copyright © 2024 Freight Canvas</Div95>
      </Div72>
    </Div71>
  );
}


const Div71 = styled.div`
  background-image:url("https://cdn.prod.website-files.com/66bd963fc68ecdd974d5d3d9/673e2b20f3a19fa144ccf1fe_Wave.png");
  background-position: 0 0;
  background-color: #0b3764;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;
const Div72 = styled.div`
  display: flex;
  margin-top: 26px;
  width: 100%;
  max-width: 1075px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div74 = styled.div`
  gap: 50px;
  display: flex;
  justify-content: space-between;
  width:100%;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;


const Img17 = styled.img`
  aspect-ratio: 5.26;
  object-fit: auto;
  object-position: center;
  width: 201px;
  max-width: 80%;
  margin-right: 4rem;

`;

const Column14 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Div79 = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const Div80 = styled.div`
     color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
    font-size: 0.975rem;
    font-weight: 700;
    line-height: 100%;
`;

const Div81 = styled.div`
color: #fff;
margin-top: 0.75rem;
margin-bottom: 0.375rem;
font-size: 0.86rem;
line-height: 100%;
`;

const Div94 = styled.div`
  background-color: #e4ebf3;
  margin-top: 65px;
  width: 100%;
  height: 1px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
const Div95 = styled.div`
  color: #fff;
  text-align: center;
  align-self: center;
  margin-top: 33px;
  font: 300 14px Montserrat, sans-serif;
  color: #fff;
    text-align: center;
    font-size: .875rem;
    line-height: 100%;
    font-weight:600
`;


